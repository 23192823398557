<template>
  <div class="content">
    <h1 class="title title--indent title--theme">Добавление Письмо-вызов</h1>
    <form @submit.prevent="onCheckForm">
      <app-grid class="grid--indent">
        <template #item-1>
          <app-form-group required label="Дата письма" label-for="date">
            <app-input
              v-model="form.date"
              v-mask="'99.99.9999'"
              :error="$v.form.date.$error"
              placeholder="дд.мм.гггг"
              autocomplete="off"
              id="date"
              @paste.native.prevent
            />
            <template #error>
              <div v-if="$v.form.date.$dirty && !$v.form.date.underscorePresent">Заполните поле полностью</div>
              <div v-if="$v.form.date.$dirty && !$v.form.date.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="Ваш адрес электронной почты" label-for="email">
            <app-input
              v-model="form.email"
              id="email"
              type="email"
              placeholder="Введите значение"
              :error="$v.form.email.$error"
            />
            <template #error>
              <div v-if="$v.form.email.$dirty && !$v.form.email.required">Обязательное поле</div>
              <div v-if="$v.form.email.$dirty && !$v.form.email.email">Некорректный формат электронной почты</div>
            </template>
          </app-form-group>
          <app-form-group label="Адрес электронной почты организации, куда необходимо отправить подготовленное письмо" label-for="email_org">
            <template #additional>
              <app-tooltip>
                <template #icon>
                  <icon-tooltip />
                </template>
                <template #content>
                  <span>Если не указан, подготовленное письмо будет отправлено на ваш адрес электронной почты</span>
                </template>
              </app-tooltip>
            </template>
            <app-input
              v-model="form.email_org"
              id="email_org"
              type="email"
              placeholder="Введите значение"
              :error="$v.form.email_org.$error"
            />
            <template #error>
              <div v-if="$v.form.email_org.$dirty && !$v.form.email_org.email">Некорректный формат электронной почты</div>
            </template>
          </app-form-group>
          <app-form-group label="Должность лица, которому адресовано письмо (в дательном падеже, ЗАГЛАВНЫМИ БУКВАМИ)" label-for="position" required>
            <template #additional>
              <app-tooltip>
                <template #icon>
                  <icon-tooltip />
                </template>
                <template #content>
                  <span>Например: РЕКТОРУ или ПРОРЕКТОРУ ПО СОЦИАЛЬНОЙ РАБОТЕ или ДИРЕКТОРУ</span>
                </template>
              </app-tooltip>
            </template>
            <app-input
              v-model="form.position"
              id="position"
              placeholder="Введите значение"
              :error="$v.form.position.$error"
            />
            <template #error>
              <div v-if="$v.form.position.$dirty && !$v.form.position.required">Некорректный формат электронной почты</div>
            </template>
          </app-form-group>
          <app-form-group label="Полное наименование организации в соответствии с уставом (в родительном падеже, ЗАГЛАВНЫМИ БУКВАМИ)" label-for="org" required>
            <template #additional>
              <app-tooltip>
                <template #icon>
                  <icon-tooltip />
                </template>
                <template #content>
                  <span>
                    Например: ФЕДЕРАЛЬНОГО ГОСУДАРСТВЕННОГО БЮДЖЕТНОГО ОБРАЗОВАТЕЛЬНОГО УЧРЕЖДЕНИЯ ВЫСШЕГО
                    ОБРАЗОВАНИЯ "КРЕЖСКИЙ НАЦИОНАЛЬНЫЙ ИССЛЕДОВАТЕЛЬСКИЙ ПОЛИТЕХНИЧЕСКИЙ УНИВЕРСИТЕТ")
                  </span>
                </template>
              </app-tooltip>
            </template>
            <app-input
              v-model="form.org"
              id="org"
              placeholder="Введите значение"
              :error="$v.form.org.$error"
            />
            <template #error>
              <div v-if="$v.form.org.$dirty && !$v.form.org.required">Некорректный формат электронной почты</div>
            </template>
          </app-form-group>
          <app-form-group
            label="Инициалы и фамилия лица, которому адресовано письмо (в дательном падеже, ЗАГЛАВНЫМИ БУКВАМИ, с пробелом между инициалами и фамилией)"
            label-for="initials_surname"
            required
          >
            <template #additional>
              <app-tooltip>
                <template #icon>
                  <icon-tooltip />
                </template>
                <template #content>
                  <span>
                    Например: В.Г. ТЕРЕХОВУ
                  </span>
                </template>
              </app-tooltip>
            </template>
            <app-input
              v-model="form.initials_surname"
              id="initials_surname"
              placeholder="Введите значение"
              :error="$v.form.initials_surname.$error"
            />
            <template #error>
              <div v-if="$v.form.initials_surname.$dirty && !$v.form.initials_surname.required">Некорректный формат электронной почты</div>
            </template>
          </app-form-group>
        </template>
        <template #item-2>
          <app-form-group required label="Выберите окончание для слова Уважаемый(ая)">
            <app-cells position="start" :indent="false">
              <label class="radio">
                <span class="radio__text">ый</span>
                <input
                  v-model="form.ending_for_dear"
                  type="radio"
                  :value="true"
                />
                <span class="radio__radiomark"></span>
              </label>
              <label class="radio">
                <span class="radio__text">ая</span>
                <input
                  v-model="form.ending_for_dear"
                  type="radio"
                  :value="false"
                />
                <span class="radio__radiomark"></span>
              </label>
            </app-cells>
            <template #error>
              <div v-if="$v.form.ending_for_dear.$dirty && !$v.form.ending_for_dear.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group
            label="Имя и отчество лица, которому адресовано письмо (в именительном падеже, с заглавной буквы)"
            label-for="person_whom"
            required
          >
            <template #additional>
              <app-tooltip>
                <template #icon>
                  <icon-tooltip />
                </template>
                <template #content>
                  <span>
                    Например: Владимир Геннадьевич
                  </span>
                </template>
              </app-tooltip>
            </template>
            <app-input
              v-model="form.person_whom"
              id="person_whom"
              placeholder="Введите значение"
              :error="$v.form.person_whom.$error"
            />
            <template #error>
              <div v-if="$v.form.person_whom.$dirty && !$v.form.person_whom.required">Некорректный формат электронной почты</div>
            </template>
          </app-form-group>
          <app-form-group required label="Период направления участников делегации на Фестиваль (с)" label-for="period_from">
            <app-input
              v-model="form.period_from"
              v-mask="'99.99.9999'"
              :error="$v.form.period_from.$error"
              placeholder="дд.мм.гггг"
              autocomplete="off"
              id="period_from"
              @paste.native.prevent
            />
            <template #error>
              <div v-if="$v.form.period_from.$dirty && !$v.form.period_from.underscorePresent">Заполните поле полностью</div>
              <div v-if="$v.form.period_from.$dirty && !$v.form.period_from.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="Период направления участников делегации на Фестиваль (по)" label-for="period_to">
            <app-input
              v-model="form.period_to"
              v-mask="'99.99.9999'"
              :error="$v.form.period_to.$error"
              placeholder="дд.мм.гггг"
              autocomplete="off"
              id="period_to"
              @paste.native.prevent
            />
            <template #error>
              <div v-if="$v.form.period_to.$dirty && !$v.form.period_to.underscorePresent">Заполните поле полностью</div>
              <div v-if="$v.form.period_to.$dirty && !$v.form.period_to.required">Обязательное поле</div>
            </template>
          </app-form-group>
        </template>
      </app-grid>
      <app-form-group
        required
        label="Прошу рассмотреть возможность направления на Фестиваль кого? (в именительном падеже, обычно со строчной буквы, без точки в конце)"
      >
        <div class="form-table form-table--double">
          <div class="form-table__head">
            <div class="form-table__item">Фамилия, имя, отчество</div>
            <div class="form-table__item">Должность</div>
          </div>
          <div
            v-for="(item, index) in form.list"
            :key="index"
            class="form-table__row"
          >
            <button v-if="index > 0" @click="onDeleteItem(index)" class="link link--absolute"
                    type="button">
            </button>
            <div class="form-table__item" :class="{'form-table__item--error': $v.form.list.$each[index].fio.$error}">
              <input
                :id="`name${index}`"
                type="text"
                :name="`name${index}`"
                v-model.trim="item.fio"
              />
            </div>
            <div class="form-table__item" :class="{'form-table__item--error': $v.form.list.$each[index].position.$error}">
              <input
                :id="`executor${index}`"
                type="text"
                :name="`executor${index}`"
                v-model.trim="item.position"
              />
            </div>
          </div>
        </div>
        <app-button size="link" class="btn--link-icon" @click="onCloneItem" type="button">Добавить строку</app-button>
      </app-form-group>
      <app-cells>
        <app-button
          @click="onCheckForm('draft')"
          type="button"
          ref="submit"
          theme="transparent"
          :disabled="$v.form.$error"
        >
          Сохранить
        </app-button>
        <app-button
          @click="onCheckForm('review')"
          type="button"
          ref="submitSecond"
          :disabled="$v.form.$error"
        >
          Отправить на проверку
        </app-button>
      </app-cells>
    </form>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { postCallLetter } from '@/http'
import IconTooltip from '@/components/icons/IconTooltip'
import { dateFormatting } from '@/helpers'

const underscorePresent = value => {
  if (!value) return true
  else return !value.includes('_')
}

export default {
  name: 'CallLetterCreate',
  components: {
    IconTooltip,
  },
  data() {
    return {
      form: {
        list: [{fio: '', position: ''}],
      },
    }
  },
  validations: {
    form: {
      email: { required, email },
      email_org: { email },
      position: { required },
      org: { required },
      initials_surname: { required },
      ending_for_dear: { required },
      person_whom: { required },
      list: {
        $each: {
          fio: { required },
          position: { required },
        }
      },
      period_from: { required, underscorePresent },
      period_to: { required, underscorePresent },
      date: { required, underscorePresent },
    }
  },
  methods: {
    onCheckForm(status) {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.form.status_code = status
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      this.$refs.submitSecond.preload = true
      const form = {...this.form}
      form.period_from = dateFormatting(form.period_from, 'normal-to-iso-small')
      form.period_to = dateFormatting(form.period_to, 'normal-to-iso-small')
      form.date = dateFormatting(form.date, 'normal-to-iso-small')
      form.delegation = this.$store.state.delegation
      postCallLetter(form)
        .finally(() => {
          this.$refs.submit.preload = false
          this.$refs.submitSecond.preload = false
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Письмо-вызов добавлен'
          })
          this.$router.push({ name: 'call-letter-list' })
        })
    },
    onCloneItem() {
      this.form.list.push({fio: '', position: ''})
      this.$v.form.$touch()
    },
    onDeleteItem(index) {
      this.form.list.splice(index, 1)
    },
  }
}
</script>
